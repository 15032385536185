import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import { useRecoilState } from 'recoil';

import Layout from 'layout';
// UI
import LoadingPage from 'ui/LoadingPage';
// Private Route Component
import PrivateRoute from 'components/PrivateRoute';
// Import from State
import { isLoggedIn as loginState, profileState, configurationState } from 'state';
// Auth Function
import { checkLoginStatus } from 'func/auth';
// Lazy - Booking Routes
const ListLogs = React.lazy(() => import('components/UserLogs/List'));
const ViewUserLogs = React.lazy(() => import('components/UserLogs/View/ViewParent'));

function UserLogsTemplate() {
  const [profile, setProfile] = useRecoilState(profileState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [, setConfiguration] = useRecoilState(configurationState);

  // Check Login Status of User On First Run
  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }
    if (isLoggedIn) return;
    initialize();
  }, []);

  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      {profile && (
        <Layout showLogoutButton>
          <div className="w-full">
            <div className="pl-4 pr-4 pt-6 md:pl-8 md:pr-8 xl:pr-16 mb-20">
              <React.Suspense fallback="">
                <Router>
                  <PrivateRoute
                    path="/userlog/search"
                    component={ListLogs}
                    isAllowed={profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'}
                    navUrl="/404"
                  />
                  <PrivateRoute
                    path="/userlog/:id"
                    component={ViewUserLogs}
                    isAllowed={profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'}
                    navUrl="/404"
                  />
                </Router>
              </React.Suspense>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default UserLogsTemplate;
